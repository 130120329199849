
import React, { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import { useTranslation } from "react-i18next";

import Button from '../Button'

import 'swiper/css';
import sw1 from '../../img/Accounts/swiper/sw(1).png'
import sw2 from '../../img/Accounts/swiper/sw(2).png'
import sw3 from '../../img/Accounts/swiper/sw(3).png'
import sw4 from '../../img/Accounts/swiper/sw(4).png'
import sw5 from '../../img/Accounts/swiper/sw(5).png'
import sw6 from '../../img/Accounts/swiper/sw(6).png'
import sw7 from '../../img/Accounts/swiper/sw(7).png'
import sw8 from '../../img/Accounts/swiper/sw(8).png'

const swiperData = [
    {
        title: 'accounts.16',
        text: 'accounts.17',
        imgSrc: sw1
    },
    {
        title: 'accounts.18',
        text: 'accounts.19',
        imgSrc: sw2
    },
    {
        title: 'accounts.20',
        text: 'accounts.21',
        imgSrc: sw3
    },
    {
        title: 'accounts.22',
        text: 'accounts.23',
        imgSrc: sw4
    },
    {
        title: 'accounts.24',
        text: 'accounts.25',
        imgSrc: sw5
    },
    {
        title: 'accounts.26',
        text: 'accounts.27',
        imgSrc: sw6
    },
    {
        title: 'accounts.28',
        text: 'accounts.29',
        imgSrc: sw7
    },
    {
        title: 'accounts.30',
        text: 'accounts.31',
        imgSrc: sw8
    },
];

function HowToChoose () {

    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState(0);
    const [swiperInstance, setSwiperInstance] = useState(null);
      
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between">
                <div className='xl:w-[383px]'>
                    <p className="bebas text-[25px] xl:text-[50px] text-[#AD0921] leading-[1]">{t('accounts.13')}</p>
                    <p className="bebas text-[25px] xl:text-[50px] text-[#171717] leading-[1]">{t('accounts.14')}</p>
                    <p className="inter font-[300] text-[#000000] text-[17px] max-w-[383px] mt-[30px]">{t('accounts.15')}</p>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
                <div className="mt-[20px] xl:mt-0 xl:w-[900px] h-[400px] xl:h-[300px]">
                    <Swiper
                        breakpoints={{
                            1280: {
                                slidesPerView: 3, // При разрешении 1280px отображать 2 слайда
                            },
                            100: {
                                slidesPerView: 1, // При разрешении 768px и меньше отображать 1 слайд
                            },
                        }}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={1}
                        loop={true}
                        spaceBetween={0} // Отступы между слайдами
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        onSlideChange={(swiper) => {
                            setActiveIndex(swiper.activeIndex);
                            swiper.slides.forEach((slide, index) => {
                                const img = slide.querySelector('img');
                                const textElements = slide.querySelectorAll('p'); // Ищем все элементы <p> внутри слайда
                                if (index === swiper.activeIndex) {
                                    img.style.width = '300px'; // Центральное изображение
                                    img.style.filter = 'grayscale(0%)';
                                    textElements.forEach(text => {
                                        text.style.opacity = '1'; // Делаем текст видимым
                                        text.style.transition = 'opacity 0.3s'; // Плавный переход для видимости текста
                                    });
                                } else {
                                    img.style.width = '174px'; // Боковые изображения
                                    img.style.filter = 'grayscale(100%)';
                                    textElements.forEach(text => {
                                        text.style.opacity = '0'; // Прячем текст
                                        text.style.transition = 'opacity 0.3s'; // Плавный переход для скрытия текста
                                    });
                                }
                            });
                        }}
                    >
                        {swiperData.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <div className='xl:w-[300px] flex flex-col justify-center transition-all duration-300'>
                                    <img 
                                        src={slide.imgSrc} 
                                        alt={t(slide.title)} 
                                        className='transition-all duration-300 xl:mx-auto'
                                    />
                                    <p className='mt-[20px] inter text-[17px] font-[500]'>{t(slide.title)}</p>
                                    <p className='mt-[10px] inter text-[17px] font-[300]'>{t(slide.text)}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>   
                </div>
            </div>
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col'>
                <div className='flex flex-col xl:flex-row ml-auto '>
                    <div className='flex items-center gap-[20px] cursor-pointer' onClick={() => swiperInstance && swiperInstance.slideNext()}>
                        <p className='inter text-[16px] font-[700] text-[#313131]'>{t('accounts.32')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                            <rect width="35" height="35" rx="17.5" fill="#AD0921"/>
                            <path d="M7.29175 17.5H27.7084" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.29297L27.7083 17.5013L17.5 27.7096" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowToChoose;