import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

function BonusSystem () {

    const { t } = useTranslation();
      
    return(
        <div>
            <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
                <div className="mx-[20px] xl:mx-auto max-w-[1280px]">
                    <p className="bebas text-[35px] xl:text-[70px] text-[#353535] leading-[1]">{t('home.32')}</p>
                    <div className="flex flex-col xl:flex-row justify-between mt-[30px]">
                        <div>
                            <p className="max-w-[525px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030]">{t('home.33')}</p>
                            <div className="flex gap-[25px] mt-[30px] items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#AD0921"/>
                                </svg>
                                <p className="text-[#303030] inter text-[14px] xl:text-[17px] font-[300]">{t('home.35')}</p>
                            </div>
                            <div className="flex gap-[25px] mt-[20px] items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#AD0921"/>
                                </svg>
                                <p className="text-[#303030] inter text-[14px] xl:text-[17px] font-[300]">{t('home.36')}</p>
                            </div>
                            <div className="flex gap-[25px] mt-[20px] items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#AD0921"/>
                                </svg>
                                <p className="text-[#303030] inter text-[14px] xl:text-[17px] font-[300]">{t('home.37')}</p>
                            </div>
                        </div>
                        <div className="mt-[20px] xl:mt-0">
                            <p className="max-w-[525px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030]">{t('home.34')}</p>
                            <Button className="mt-[20px] xl:mt-[50px]"/>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    );
}

export default BonusSystem;