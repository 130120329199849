import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import com from '../../img/Trading/commitment.png'

function Commitment () {

    const { t } = useTranslation();
      
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between xl:items-center">
                <div>
                    <p className="bebas text-[35px] xl:text-[70px] text-[#AD0921]">{t('trading.22')}</p>
                    <p className="bebas text-[35px] xl:text-[70px] text-[#353535] mt-[10px]">{t('trading.23')}</p>
                    <p className="max-w-[525px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030] mt-[30px]">{t('trading.24')}</p>
                    <p className="max-w-[525px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030] mt-[15px]">{t('trading.25')}</p>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
                <div>
                    <Fade right>
                        <img src={com} alt="" />
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default Commitment;