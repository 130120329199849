import React, { useState } from "react";

import { useTranslation } from "react-i18next";

function AccountTypes () {

    const { t } = useTranslation();
      
    return(
        <div>
            <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
                <div className="mx-[20px] xl:mx-auto max-w-[1280px]">
                    <div className="flex flex-col xl:flex-row justify-between items-center">
                        <div>
                            <p className="bebas text-[35px] xl:text-[70px] text-[#AD0921] leading-[1]">{t('home.24')}</p>
                            <p className="bebas text-[35px] xl:text-[70px] text-[#353535] leading-[1]">{t('home.25')}</p>
                        </div>
                        <div>
                            <p className="max-w-[446px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030]">{t('home.26')}</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap xl:flex-row justify-between mt-[20px] xl:mt-[50px]">
                       <div className="w-1/5 h-[170px] flex items-center justify-center hover:bg-[#AD0921] transition-all text-[#353535] hover:text-white">
                            <p className="bebas text-[20px] xl:text-[28px]">{t('home.27')}</p>
                       </div>
                       <div className="w-1/5 h-[170px] flex items-center justify-center hover:bg-[#AD0921] transition-all text-[#353535] hover:text-white">
                            <p className="bebas text-[20px] xl:text-[28px]">{t('home.28')}</p>
                       </div>
                       <div className="w-1/5 h-[170px] flex items-center justify-center hover:bg-[#AD0921] transition-all text-[#353535] hover:text-white">
                            <p className="bebas text-[20px] xl:text-[28px]">{t('home.29')}</p>
                       </div>
                       <div className="w-1/5 h-[170px] flex items-center justify-center hover:bg-[#AD0921] transition-all text-[#353535] hover:text-white">
                            <p className="bebas text-[20px] xl:text-[28px]">{t('home.30')}</p>
                       </div>
                       <div className="w-1/5 h-[170px] flex items-center justify-center hover:bg-[#AD0921] transition-all text-[#353535] hover:text-white">
                            <p className="bebas text-[20px] xl:text-[28px]">{t('home.31')}</p>
                       </div>
                    </div>
                </div>
            </div>   
        </div>
    );
}

export default AccountTypes;