import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import checkIcon from '../../img/Home/yes.svg';
import crossIcon from '../../img/Home/no.svg';

const tableData = {
    headers: ["Account Comparison", "Beginner", "PRO", "Elite", "Alpha", "Expert"],
    rows: [
        ["Trading Platform", "TRADE", "TRADE", "TRADE", "TRADE", "TRADE"],
        ["Commission from", "X", "$30 / million", "X", "$30 / million", "$30 / million"],
        ["Spreads from (pips)", "0.7", "0.7", "0.1", "0.1", "0.1"],
        ["Starting Deposit", "250 $", "500 $", "1000 $", "5000 $", "10,000 $"],
        ["Leverage", "1 : 500", "1 : 500", "1 : 500", "1 : 500", "1 : 500"],
        ["Micro Lot Trading (0.01)", "✔", "✔", "✔", "✔", "✔"],
        ["Currency Pairs", "53", "53", "73", "73", "109"],
        ["Orders per day", "3", "6", "10", "12", "16"],
        ["Futures Contracts", "1", "4", "7", "All", "All"],
        ["Risk", "12.8%", "9.2%", "4.1%", "2.6%", "1.1%"],
        ["Insurance", "97%", "97%", "97%", "97%", "97%"],
        ["Stop Out Level", "50%", "50%", "50%", "50%", "50%"],
    ],
};

function Table () {
    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    const accountsData = tableData.headers.slice(1).map((account, index) => {
        const accountDetails = tableData.rows.map(row => row[index + 1]);
        return {
            accountType: account,
            details: accountDetails,
        };
    });

    return (
        <div className="pb-[80px] xl:pb-0 xl:pt-[160px] relative overflow-hidden">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                {/* Swiper for mobile table */}
                <div className="block xl:hidden mt-[40px]">
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                    >
                        {accountsData.map((account, accountIndex) => (
                            <SwiperSlide key={accountIndex} className="bg-[#F5F5F5] rounded-lg p-4">
                                <h4 className="font-bold mb-4 roboto text-[#D91E39] text-center">{account.accountType}</h4>
                                <div className="flex flex-col space-y-2">
                                    {tableData.rows.map((row, rowIndex) => (
                                        <div key={rowIndex} className="flex justify-between mb-2">
                                            <span className="text-[#000000B2] roboto">{row[0]}</span>
                                            {account.details[rowIndex] === "✔" ? (
                                                <img src={checkIcon} alt="check" className="w-5 h-5" />
                                            ) : account.details[rowIndex] === "✘" ? (
                                                <img src={crossIcon} alt="cross" className="w-5 h-5" />
                                            ) : (
                                                <p className="roboto text-[#000000B2]">{account.details[rowIndex]}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="flex gap-[20px] mt-[20px] xl:mt-0">
                        <div className="w-[34px] h-[34px] xl:w-[54px] xl:h-[54px] border-[2px] border-[#D91E39] cursor-pointer transition-all flex items-center justify-center hover-fill" onClick={() => swiper.slidePrev()}>
                            <svg className="scale-75 xl:scale-100" width="29" height="14" viewBox="0 0 29 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.863602 6.3636C0.512131 6.71507 0.512131 7.28492 0.863602 7.63639L6.59117 13.364C6.94264 13.7154 7.51249 13.7154 7.86396 13.364C8.21543 13.0125 8.21543 12.4426 7.86396 12.0912L2.77279 7L7.86396 1.90883C8.21543 1.55736 8.21543 0.987509 7.86396 0.636037C7.51249 0.284566 6.94264 0.284565 6.59117 0.636037L0.863602 6.3636ZM28.5 6.1L1.5 6.1L1.5 7.9L28.5 7.9L28.5 6.1Z" fill="#D91E39"/>
                            </svg>
                        </div>
                        <div className="w-[34px] h-[34px] xl:w-[54px] xl:h-[54px] border-[2px] border-[#D91E39] cursor-pointer transition-all flex items-center justify-center hover-fill" onClick={() => swiper.slideNext()}>
                            <svg width="29" height="14" viewBox="0 0 29 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotate-180 scale-75 xl:scale-100">
                                <path d="M0.863602 6.3636C0.512131 6.71507 0.512131 7.28492 0.863602 7.63639L6.59117 13.364C6.94264 13.7154 7.51249 13.7154 7.86396 13.364C8.21543 13.0125 8.21543 12.4426 7.86396 12.0912L2.77279 7L7.86396 1.90883C8.21543 1.55736 8.21543 0.987509 7.86396 0.636037C7.51249 0.284566 6.94264 0.284565 6.59117 0.636037L0.863602 6.3636ZM28.5 6.1L1.5 6.1L1.5 7.9L28.5 7.9L28.5 6.1Z" fill="#D91E39"/>
                            </svg>
                        </div>
                    </div>
                </div>

                {/* Desktop table */}
                <div className="hidden xl:block pt-[60px]">
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr className="bg-[#D91E39]">
                                {tableData.headers.map((header, index) => (
                                    <th key={index} className="px-4 py-4">
                                        <p className="text-white roboto font-[500] text-[20px]">{header}</p>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.rows.map((row, rowIndex) => (
                                <tr key={rowIndex} className={rowIndex % 2 === 0 ? "bg-white" : "bg-[#F5F5F5]"}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} className="px-4 py-2">
                                            {cell === "✔" ? (
                                                <img src={checkIcon} alt="check" className="w-5 h-5 mx-auto" />
                                            ) : cell === "✘" ? (
                                                <img src={crossIcon} alt="cross" className="w-5 h-5 mx-auto" />
                                            ) : (
                                                <p className="roboto text-[#000000B2] text-center">{cell}</p>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Table;
