import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Marquee from "react-fast-marquee";

import Button from '../Button'

import m1 from '../../img/Support/main.png'
import Fade from 'react-reveal/Fade'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="relative overflow-hidden">
            <div className="relative overflow-hidden h-[800px] bg-[#171717]">
                <div className="flex flex-col md:flex-row justify-between mx-[20px] xl:mx-auto max-w-[1280px]">
                    <div className="mt-[50px] xl:mt-[180px]">
                        <p className="bebas text-[#AD0921] text-[70px] xl:text-[140px] leading-[1]">{t('support.1')}</p>
                        <p className="text-white bebas text-[35px] xl:text-[70px] leading-[1]">{t('support.2')}</p>
                        <p className="max-w-[525px] mt-[30px] inter text-[14px] xl:text-[17px] font-[200] text-white">{t('support.3')}</p>
                        <Button className="mt-[20px] xl:mt-[50px]"/>
                    </div>
                    <div className="mt-[60px]">
                        <Fade right><img src={m1} alt="" className="xl:absolute top-0 right-0"/></Fade>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default Main;