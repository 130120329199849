import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import InfoBlock1 from "../components/Home/InfoBlock1";
import Trading from "../components/Home/Trading";
import AccountTypes from "../components/Home/AccountTypes";
import BonusSystem from "../components/Home/BonusSystem";
import TheLegacy from "../components/Home/TheLegacy";
import SignUpToStart from "../components/Home/SignUpToStart";
import Table from "../components/Home/Table";
import Supported from "../components/Home/Supported";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Table/>
            <InfoBlock1/>
            <Trading/>
            <AccountTypes/>
            <BonusSystem/>
            <TheLegacy/>
            <Supported/>
            <SignUpToStart/>
        </div>
    );
}

export default Home;