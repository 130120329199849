import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from '../Button'

import i1 from '../../img/Home/i1.png'
import i2 from '../../img/Home/i2.png'

function InfoBlock1 () {

    const { t, i18n } = useTranslation();

    const isBulgarian = i18n.language === 'bg';
      
    return(
        <div>
            <div className="relative overflow-hidden xl:pt-[200px]">
                <div className="flex flex-col xl:flex-row justify-between mx-[20px] xl:mx-auto max-w-[1280px]">
                   <div>
                        <img src={i1} alt=""  className="w-full h-full"/>
                   </div>
                   <div className="xl:max-w-[593px] mt-[20px] xl:mt-0">
                        <p className={`inter text-[14px] font-[200] text-[#303030] ${isBulgarian ? '' : 'xl:text-[16px]'}`}>{t('home.7')}</p>
                   </div>
                </div>
                <div className="mx-[20px] xl:mx-auto max-w-[1280px] relative mt-[40px] xl:mt-0">
                    <div className="flex flex-col xl:flex-row gap-[20px] xl:gap-[30px] xl:absolute top-[-266px] right-0">
                        <div>
                            <img src={i2} alt="" className="w-full h-full"/>
                        </div>
                        <div className="max-w-[446px] flex flex-col justify-between">
                            <p className="inter text-[14px] xl:text-[16px] font-[200] text-[#303030]">{t('home.8')}</p>
                            <Button className="mt-[20px] xl:mt-0"/>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    );
}

export default InfoBlock1;