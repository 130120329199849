import React from "react";

import { useTranslation } from "react-i18next";

function Button ( { className } ) {

    const { t } = useTranslation();
      
    return(
        <div className={className}>
            <a href="https://user.anda-trade.net/">
                <div className="button_main flex cursor-pointer gap-[20px]">
                    <p className="text-white inter font-[700] text-[16px]">{t('home.4')}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                        <rect width="35" height="35" rx="17.5" fill="#AD0921"/>
                        <path d="M7.29175 17.5H27.7084" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </a>
        </div>
    );
}

export default Button;