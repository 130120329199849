import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from '../Button'

function Contacting () {

    const { t } = useTranslation();
      
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="bebas text-[35px] xl:text-[70px] text-[#AD0921]">{t('support.4')}</p>
                    <p className="bebas text-[35px] xl:text-[70px] text-[#353535]">{t('support.5')}</p>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
                <div className="flex flex-col gap-[20px] mt-[20px] xl:mt-0">
                    <div className="xl:w-[593px] h-[97px] bg-[#AD0921] flex items-center px-[25px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                            <rect width="47" height="47" rx="23.5" fill="white"/>
                            <path d="M9.79175 23.5H37.2084" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.5 9.79102L37.2083 23.4993L23.5 37.2077" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-white bebas text-[16px] xl:text-[28px] ml-[25px]">{t('support.6')}</p>
                    </div>
                    <div className="xl:w-[593px] h-[97px] bg-[#AD0921] flex items-center px-[25px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                            <rect width="47" height="47" rx="23.5" fill="white"/>
                            <path d="M9.79175 23.5H37.2084" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.5 9.79102L37.2083 23.4993L23.5 37.2077" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-white bebas text-[16px] xl:text-[28px] ml-[25px]">{t('support.7')}</p>
                    </div>
                    <div className="xl:w-[593px] h-[97px] bg-[#AD0921] flex items-center px-[25px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                            <rect width="47" height="47" rx="23.5" fill="white"/>
                            <path d="M9.79175 23.5H37.2084" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.5 9.79102L37.2083 23.4993L23.5 37.2077" stroke="#313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-white bebas text-[16px] xl:text-[28px] ml-[25px]">{t('support.8')}</p>
                    </div>
                </div>
            </div> 
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between pt-[20px] xl:pt-[200px]">
                <div className="max-w-[525px]">
                    <p className="inter font-[200] text-[14px] xl:text-[17px]">{t('support.9')}</p>
                    <Button className="mt-[20px] xl:mt-[50px] hidden xl:block"/>
                </div>
                <div className="mt-[20px] xl:mt-0 xl:w-[525px]">
                    <div className="flex gap-[20px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                            <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#AD0921"/>
                        </svg>
                        <p className="inter text-[14px] xl:text-[17px] text-[#303030] font-[300]">{t('support.10')}</p>
                    </div>
                    <div className="flex gap-[20px] mt-[20px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                            <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#AD0921"/>
                        </svg>
                        <p className="inter text-[14px] xl:text-[17px] text-[#303030] font-[300]">{t('support.11')}</p>
                    </div>
                    <div className="flex gap-[20px] mt-[20px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                            <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#AD0921"/>
                        </svg>
                        <p className="inter text-[14px] xl:text-[17px] text-[#303030] font-[300]">{t('support.12')}</p>
                    </div>
                    <p className="inter font-[200] text-[14px] xl:text-[17px] mt-[20px] xl:mt-[50pxc]">{t('support.13')}</p>
                    <Button className="mt-[20px] xl:mt-[50px] xl:hidden block"/>
                </div>
            </div> 
        </div>
    );
}

export default Contacting;