import React from "react";

import Marque from 'react-fast-marquee'

import { useTranslation } from "react-i18next";

import sup1 from '../../img/Home/supp/sup1.png'
import sup2 from '../../img/Home/supp/sup2.png'
import sup3 from '../../img/Home/supp/sup3.png'
import sup4 from '../../img/Home/supp/sup4.png'
import sup5 from '../../img/Home/supp/sup5.png'
import sup6 from '../../img/Home/supp/sup6.png'
import sup7 from '../../img/Home/supp/sup7.png'
import sup8 from '../../img/Home/supp/sup8.png'

function Supported() {

    const { t } = useTranslation();

    return (
        <div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px] flex">
                <p className="mx-auto text-center bebas text-[35px] xl:text-[70px] text-[#AD0921] leading-[1]">{t('marque')}</p>
            </div>
            <Marque className="pt-[40px] xl:pt-[80px]" autoFill>
                <img src={sup1} alt="" className="px-[40px]"/>
                <img src={sup2} alt="" className="px-[40px]" />
                <img src={sup3} alt="" className="px-[40px]" />
                <img src={sup4} alt="" className="px-[40px]" />
                <img src={sup5} alt="" className="px-[40px]" />
                <img src={sup6} alt="" className="px-[40px]" />
                <img src={sup7} alt="" className="px-[40px]" />
                <img src={sup8} alt="" className="px-[40px]" />
            </Marque>
        </div>
    );
}

export default Supported;
