import React, { useEffect } from "react";

import Main from '../components/Trading/Main'
import TradingTools from "../components/Trading/TradingTools";
import Commitment from "../components/Trading/Commitment";
import SignUpToStart from "../components/Home/SignUpToStart";

function Trading () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <TradingTools/>
            <Commitment/>
            <SignUpToStart/>
        </div>
    );
}

export default Trading;