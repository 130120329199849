import React, { useEffect } from "react";

import Main from '../components/Bonus/Main'
import BonusSystem from "../components/Bonus/BonusSystem";
import Terms from "../components/Bonus/Terms";
import BonusSize from "../components/Bonus/BonusSize";
import Fees from "../components/Bonus/Fees";

function Bonus () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <BonusSystem/>
            <Terms/>
            <BonusSize/>
            <Fees/>
        </div>
    );
}

export default Bonus;