import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import logo_mob from "../img/logo.png";
import MobileMenu from "./MobileMenu";

function Header() {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const isBulgarian = i18n.language === 'bg';

    const languages = [
        { value: 'en', label: 'EN', flag: 'https://flagcdn.com/w20/gb.png' },
        { value: 'de', label: 'DE', flag: 'https://flagcdn.com/w20/de.png' },
        { value: 'it', label: 'IT', flag: 'https://flagcdn.com/w20/it.png' },
        { value: 'jp', label: 'JP', flag: 'https://flagcdn.com/w20/jp.png' },
        { value: 'pt', label: 'PT', flag: 'https://flagcdn.com/w20/pt.png' },
        { value: 'ro', label: 'RO', flag: 'https://flagcdn.com/w20/ro.png' },
        { value: 'tr', label: 'TR', flag: 'https://flagcdn.com/w20/tr.png' },
        { value: 'bg', label: 'BG', flag: 'https://flagcdn.com/w20/bg.png' },
        { value: 'kr', label: 'KR', flag: 'https://flagcdn.com/w20/kr.png' },
        { value: 'pl', label: 'PL', flag: 'https://flagcdn.com/w20/pl.png' },
        { value: 'es', label: 'ES', flag: 'https://flagcdn.com/w20/es.png' },
    ];

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
        document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    const customOption = ({ value, label, flag }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={flag} alt={label} style={{ width: '20px', marginRight: '10px' }} />
            {label}
        </div>
    );
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '1px solid #ECECEC',
            boxShadow: 'none',
            '&:hover': { border: '1px solid #ECECEC' },
            padding: '5px',
            backgroundColor: '#ECECEC'
        }),
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: state.isSelected ? '#AD0921' : '#fff',

        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
    };
    return (
        <div className={`header-container xl:pt-[10px] ${location.pathname === '/accounts' ? '' : 'bg-[#171717]'}`}>
            <div className="hidden max-w-[1280px] h-[80px] justify-between mx-auto xl:flex items-center bg-[#ECECEC] rounded-[60px] px-[20px]">
                <Link to="/"><img src={logo} alt="" className="w-[120px]"/></Link>
                <Link to="/trading"><p className={`${isBulgarian ? 'text-[14px]' : 'text-[16px]'} open-sans ${location.pathname === '/trading' ? 'text-[#AD0921] font-[500]' : 'text-[#313131]'}`}>{t('header.1')}</p></Link>
                <Link to="/bonus"><p className={`${isBulgarian ? 'text-[14px]' : 'text-[16px]'} open-sans ${location.pathname === '/bonus' ? 'text-[#AD0921] font-[500]' : 'text-[#313131]'}`}>{t('header.2')}</p></Link>
                <Link to="/accounts"><p className={`${isBulgarian ? 'text-[14px]' : 'text-[16px]'} open-sans ${location.pathname === '/accounts' ? 'text-[#AD0921] font-[500]' : 'text-[#313131]'}`}>{t('header.3')}</p></Link>
                <Link to="/support"><p className={`${isBulgarian ? 'text-[14px]' : 'text-[16px]'} open-sans ${location.pathname === '/support' ? 'text-[#AD0921] font-[500]' : 'text-[#313131]'}`}>{t('header.4')}</p></Link>
                <Link to="/aboutus"><p className={`${isBulgarian ? 'text-[14px]' : 'text-[16px]'} open-sans ${location.pathname === '/aboutus' ? 'text-[#AD0921] font-[500]' : 'text-[#313131]'}`}>{t('header.5')}</p></Link>
                <Select
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    getOptionLabel={customOption}
                    getOptionValue={(option) => option.value} // Для корректной работы с выбранным значением
                    styles={customStyles} 
                />
                <a href="https://user.anda-trade.net/">
                    <div className="buttonheader cursor-pointer">
                        <p className="open-sans text-white text-[16px] font-[700]">{t('header.button')}</p>
                    </div>
                </a>
            </div>
            <div className="xl:hidden flex items-center justify-between h-[60px] px-[20px] bg-[#ECECEC]">
                <Link to="/"><img src={logo_mob} alt="" className="w-[80px]"/></Link>
                <div className="flex items-center gap-[10px]">
                    <Select
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                        getOptionLabel={customOption}
                        getOptionValue={(option) => option.value} // Для корректной работы с выбранным значением
                        styles={customStyles} 
                    />
                    <MobileMenu/>
                </div>
            </div>
        </div>
    );
}

export default Header;
