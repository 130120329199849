import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from '../Button'

import button from '../../img/Home/button.png'

const faqData = [
    {
        question: 'bonus.6',
        answer: 'bonus.7'
    },
    {
        question: 'bonus.8',
        answer: 'bonus.9'
    },
    {
        question: 'bonus.10',
        answer: 'bonus.11'
    },
]


function BonusSystem () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    
    return(
        <div className="relative overflow-hidden mt-[80px] xl:mt-[200px]">
           <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <div className="flex items-center gap-[6px]">
                        <div className="w-[12px] h-[12px] bg-[#AD0921]"></div>
                        <p className="inter text-[17px] inter text-[#37393F] font-[500] mt-[3px]">{t('bonus.4')}</p>
                    </div>   
                    <p className="max-w-[525px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030] mt-[30px]">{t('bonus.5')}</p>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
                <div className="xl:w-[525px] mt-[20px] xl:mt-0">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`flex justify-between transition-all duration-300 ${openIndex === index ? 'h-auto bg-[#AD0921]' : 'h-[86px] bg-[#F5F5F5]'}`}>
                                <div>
                                    <p className={`mt-[25px] bebas text-[20px] xl:text-[28px] max-w-[200px] xl:max-w-[1280px] ml-[30px] ${openIndex === index ? 'text-white' : 'text-[#313131]'}`}>{t(faq.question)}</p>
                                    {openIndex === index && (
                                        <p className={`text-[#313131] inter text-[14px] xl:text-[17px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px] ml-[30px] ${openIndex === index ? 'text-white' : 'text-[#313131]'}`}>{t(faq.answer)}</p>
                                    )}
                                </div>
                                <div className='cursor-pointer mt-[16px] flex-shrink-0' onClick={() => toggleText(index)}>
                                    <svg className={`${openIndex === index ? 'transition-transform rotate-90' : 'transition-transform'}`} xmlns="http://www.w3.org/2000/svg" width="55" height="56" viewBox="0 0 55 56" fill="none">
                                        <path d="M13.8087 39.7407L36.8676 17.5647" stroke={openIndex === index ? '#FFF' : '#313131'} stroke-width="4.57029" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M36.4261 40.1821L36.8676 17.5647L14.2501 17.1232" stroke={openIndex === index ? '#FFF' : '#313131'} stroke-width="4.57029" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#313131]"></div>
                        </div>
                    ))}
                </div>
           </div>
        </div>
    );
}

export default BonusSystem;