import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import vec from '../../img/About/Vector.png'

const data = [
    {
        title: "about.4",
        text: "about.7",
        o1: 'about.8',
        o2: 'about.9',
        o3: 'about.10',
        o4: 'about.11',
        o5: 'about.12',
        text2: 'about.13',
        text3: 'about.14',
    },
    {
        title: "about.5",
        text: "about.15",
    },
    {
        title: "about.6",
        o1: 'about.16',
        o2: 'about.17',
        o3: 'about.18',
        text2: 'about.19',
        text3: 'about.20',
    },

]

function Switcher () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);
      
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
            <div className="flex flex-col md:flex-row gap-[20px] xl:gap-[65px] mx-[20px] xl:mx-auto max-w-[1280px]">
                <div>
                    {data.map((sl, index) => (
                        <div className={`px-[20px] xl:w-[316px] h-[74px] transition-all flex items-center justify-between border-b cursor-pointer ${active === index ? 'bg-[#AD0921]' : ''}`} onClick={() => setActive(index)}>
                            <p className={`uppercase transition-all font-[700] ${active === index ? 'text-white' : 'text-black'}`}>{t(sl.title)}</p>
                            <div className={`w-[34px] h-[34px] rounded-full bg-white flex items-center justify-center ${active === index ? 'block' : 'hidden'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.75 8.00098L12.25 8.00098" stroke="#171717" stroke-width="1.21429" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8 12.251L12.25 8.00098L8 3.75098" stroke="#171717" stroke-width="1.21429" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <p className="bebas text-[25px] xl:text-[50px] text-[#AD0921]">{t(data[active].title)}</p>
                    <p className="inter text-[14px] xl:text-[17px] font-[200] mt-[20px]">{t(data[active].text)}</p>
                    <div className="grid grid-cols-2 mt-[30px] gap-[25px]">
                        {data[active].o1 && (
                            <div className="flex gap-[15px] xl:gap-[25px] items-center">
                            <img src={vec} alt="" />
                            <p className="inter text-[14px] xl:text-[17px] font-[300]">{t(data[active].o1)}</p>
                            </div>
                        )}
                        {data[active].o2 && (
                            <div className="flex gap-[15px] xl:gap-[25px] items-center">
                            <img src={vec} alt="" />
                            <p className="inter text-[14px] xl:text-[17px] font-[300]">{t(data[active].o2)}</p>
                            </div>
                        )}
                        {data[active].o3 && (
                            <div className="flex gap-[15px] xl:gap-[25px] items-center">
                            <img src={vec} alt="" />
                            <p className="inter text-[14px] xl:text-[17px] font-[300]">{t(data[active].o3)}</p>
                            </div>
                        )}
                        {data[active].o4 && (
                            <div className="flex gap-[15px] xl:gap-[25px] items-center">
                            <img src={vec} alt="" />
                            <p className="inter text-[14px] xl:text-[17px] font-[300]">{t(data[active].o4)}</p>
                            </div>
                        )}
                        {data[active].o5 && (
                            <div className="flex gap-[15px] xl:gap-[25px] items-center">
                            <img src={vec} alt="" />
                            <p className="inter text-[14px] xl:text-[17px] font-[300]">{t(data[active].o5)}</p>
                            </div>
                        )}
                    </div>
                    <p className="inter text-[14px] xl:text-[17px] font-[200] mt-[20px]">{t(data[active].text2)}</p>
                    <p className="inter text-[14px] xl:text-[17px] font-[200] mt-[20px]">{t(data[active].text3)}</p>
                </div>
            </div> 
        </div>
    );
}

export default Switcher;