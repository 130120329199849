import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import button from '../../img/Home/button.png'

const faqData = [
    {
        question: 'accounts.34',
        answer: 'accounts.35'
    },
    {
        question: 'accounts.36',
        answer: 'accounts.37'
    },
    {
        question: 'accounts.38',
        answer: 'accounts.39'
    },

    {
        question: 'accounts.40',
        answer: 'accounts.41'
    },
]

function FAQ () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
      
    return(
        <div>
            <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
                <div className="mx-[20px] xl:mx-auto max-w-[1280px]">
                    <p className="bebas text-[35px] xl:text-[70px] text-[#353535] leading-[1]">{t('accounts.33')}</p>
                    <div className="flex flex-col mt-[20px] xl:mt-[50px]">
                        {faqData.map((faq, index) => (
                            <div key={index}>
                                <div className={`flex bg-[#F5F5F5] justify-between transition-all duration-300 ${openIndex === index ? 'h-auto' : 'h-[86px]'}`}>
                                    <div>
                                        <p className='mt-[25px] bebas text-[20px] xl:text-[28px] text-[#313131] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                        {openIndex === index && (
                                            <p className='text-[#313131] inter text-[14px] xl:text-[17px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px]'><Trans>{t(faq.answer)}</Trans></p>
                                        )}
                                    </div>
                                    <div className='cursor-pointer mt-[16px] flex-shrink-0' onClick={() => toggleText(index)}>
                                        <img src={button} alt="" className={`${openIndex === index ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                                    </div>
                                </div>
                                <div className="h-[1px] w-full bg-[#313131]"></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>   
        </div>
    );
}

export default FAQ;