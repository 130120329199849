import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

function Main () {

    const { t, i18n } = useTranslation();

    const isBulgarian = i18n.language === 'bg';
      
    return(
        <div className="relative overflow-hidden h-[900px] xl:h-[800px] bg-[#171717]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between xl:items-center">
                <div className="mt-[50px] xl:mt-[100px]">
                    <p className={`bebas text-[#AD0921] text-[70px] leading-[1] ${isBulgarian ? 'xl:text-[100px]' : 'xl:text-[140px]'}`}>{t('trading.1')}</p>
                    <p className="text-white bebas text-[35px] xl:text-[70px] leading-[1]">{t('trading.2')}</p>
                    <p className="max-w-[525px] mt-[30px] inter text-[14px] xl:text-[17px] font-[200] text-white">{t('trading.3')}</p>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <a href="https://user.anda-trade.net/login"><div className="flex gap-[20px] items-center">
                        <p className="text-[25px] xl:text-[40px] bebas text-[#FFF]">{t('trading.4')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect y="0.15625" width="35" height="35" rx="17.5" fill="#AD0921"/>
                            <path d="M7.29163 17.6562H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.44824L27.7083 17.6566L17.5 27.8649" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></a>
                    <a href="https://user.anda-trade.net/login"><div className="flex gap-[20px] items-center">
                        <p className="text-[25px] xl:text-[40px] bebas text-[#FFF]">{t('trading.5')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect y="0.15625" width="35" height="35" rx="17.5" fill="#AD0921"/>
                            <path d="M7.29163 17.6562H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.44824L27.7083 17.6566L17.5 27.8649" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></a>
                    <a href="https://user.anda-trade.net/login"><div className="flex gap-[20px] items-center">
                        <p className="text-[25px] xl:text-[40px] bebas text-[#FFF]">{t('trading.6')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect y="0.15625" width="35" height="35" rx="17.5" fill="#AD0921"/>
                            <path d="M7.29163 17.6562H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.44824L27.7083 17.6566L17.5 27.8649" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></a>
                    <a href="https://user.anda-trade.net/login"><div className="flex gap-[20px] items-center">
                        <p className="text-[25px] xl:text-[40px] bebas text-[#FFF]">{t('trading.add.1')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect y="0.15625" width="35" height="35" rx="17.5" fill="#AD0921"/>
                            <path d="M7.29163 17.6562H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.44824L27.7083 17.6566L17.5 27.8649" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></a>
                    <a href="https://user.anda-trade.net/login"><div className="flex gap-[20px] items-center">
                        <p className="text-[25px] xl:text-[40px] bebas text-[#FFF]">{t('trading.add.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect y="0.15625" width="35" height="35" rx="17.5" fill="#AD0921"/>
                            <path d="M7.29163 17.6562H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.44824L27.7083 17.6566L17.5 27.8649" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></a>
                    <a href="https://user.anda-trade.net/login"><div className="flex gap-[20px] items-center">
                        <p className="text-[25px] xl:text-[40px] bebas text-[#FFF]">{t('trading.add.3')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect y="0.15625" width="35" height="35" rx="17.5" fill="#AD0921"/>
                            <path d="M7.29163 17.6562H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.44824L27.7083 17.6566L17.5 27.8649" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></a>
                    <a href="https://user.anda-trade.net/login"><div className="flex gap-[20px] items-center">
                        <p className="text-[25px] xl:text-[40px] bebas text-[#FFF]">{t('trading.add.4')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect y="0.15625" width="35" height="35" rx="17.5" fill="#AD0921"/>
                            <path d="M7.29163 17.6562H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.44824L27.7083 17.6566L17.5 27.8649" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></a>
                </div>
            </div>
            <div className="h-[135px] bg-[#CAC9C9] w-full absolute bottom-0">
                <div className="max-w-[1280px] xl:mx-auto mx-[20px] grid grid-cols-3 pt-[40px] xl:pt-[30px]">
                    <Fade bottom delay={500}><div className="flex flex-col items-center">
                        <p className="text-[#171717] bebas text-[16px] xl:text-[20px] bebas">{t('trading.7')}</p>
                        <p className="text-[#AD0921] text-[20px] xl:text-[40px] bebas">{t('trading.8')}</p>
                    </div></Fade>
                    <Fade bottom delay={700}><div className="flex flex-col items-center">
                        <p className="text-[#171717] bebas text-[16px] xl:text-[20px] bebas">{t('trading.9')}</p>
                        <p className="text-[#AD0921] text-[20px] xl:text-[40px] bebas text-center">{t('trading.10')}</p>
                    </div></Fade>
                    <Fade bottom delay={900}><div className="flex flex-col items-center">
                        <p className="text-[#171717] bebas text-[16px] xl:text-[20px] bebas">{t('trading.11')}</p>
                        <p className="text-[#AD0921] text-[20px] xl:text-[40px] bebas text-center">{t('trading.12')}</p>
                    </div></Fade>
                </div>
            </div>
        </div>
    );
}

export default Main;