import React, { useEffect } from "react";

import Main from '../components/Accounts/Main'
import HowToChoose from "../components/Accounts/HowToChoose";
import FAQ from "../components/Accounts/FAQ";
import SignUpToStart from "../components/Home/SignUpToStart";
import Table from "../components/Home/Table";

function Accounts () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Table/>
            <HowToChoose/>
            <FAQ/>
            <SignUpToStart/>
        </div>
    );
}

export default Accounts;