import React, { useEffect } from "react";

import Main from '../components/About/Main'
import Switcher from "../components/About/Switcher";
import SignUpToStart from "../components/Home/SignUpToStart";

function About () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Switcher/>
            <SignUpToStart/>
        </div>
    );
}

export default About;