import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const data = [
    {
        title: 'home.9',
        title1: 'home.11',
        title2: 'home.12',
        text: 'home.13',
        texto1: 'home.14',
        texto2: 'home.15',
        texto3: 'home.16',
        texto4: 'home.17',
        texto5: 'trading.add.1',
        texto6: 'trading.add.2',
        texto7: 'trading.add.3',
        texto8: 'trading.add.4',
    },
    {
        title: 'home.10',
        title1: 'home.11',
        title2: 'home.18',
        text: 'home.19',
        texto1: 'home.20',
        texto2: 'home.21',
        texto3: 'home.22',
        texto4: 'home.23',
    }
]

function Trading () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);
      
    return(
        <div>
            <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
                <div className="mx-[20px] xl:mx-auto max-w-[1280px]">
                    <div className="flex gap-[50px]">
                        {data.map((dat, index) => (
                            <div className={`button_trading rounded-[25px] transition-all cursor-pointer ${active === index ? 'bg-[#AD0921] border border-[#AD0921]' : 'border border-[#353535]'}`} key={index} onClick={() => setActive(index)}>
                                <p className={`inter font-[700] transition-all ${active ===index ? 'text-white' : '#353535'}`}>{t(dat.title)}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                    <rect width="35" height="35" rx="17.5" fill="#AD0921"/>
                                    <path d="M7.29175 17.5H27.7084" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col xl:flex-row justify-between mt-[40px]">
                        <div className="xl:w-[800px]">
                            <p className="bebas text-[#AD0921] text-[70px] xl:text-[140px] leading-[1]">{t(data[active].title1)}</p>
                            <div className="flex flex-col xl:flex-row justify-between">
                                <p className="bebas text-[35px] xl:text-[70px] text-[#353535]">{t(data[active].title2)}</p>
                                <p className="max-w-[418px] inter font-[200] text-[14px] xl:text-[17px]">{t(data[active].text)}</p>
                            </div>
                        </div>
                        <div className="mt-[20px] xl:mt-[60px] xl:w-[420px] flex flex-col gap-[10px] xl:gap-0">
                            {[...Array(8)].map((_, index) => {
                                const textoKey = `texto${index + 1}`;
                                const textoValue = t(data[active][textoKey]);
                                return textoValue ? (
                                    <a href="https://user.anda-trade.net/login"><div key={textoKey} className="flex gap-[20px] items-center">
                                        <p className="text-[25px] xl:text-[50px] bebas text-[#353535]">{textoValue}</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                                            <rect y="0.15625" width="35" height="35" rx="17.5" fill="#AD0921" />
                                            <path d="M7.29163 17.6562H27.7083" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M17.5 7.44824L27.7083 17.6566L17.5 27.8649" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div></a>
                                ) : null;
                            })}
                        </div>  
                    </div>
                </div>
            </div>   
        </div>
    );
}

export default Trading;