import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Fade from 'react-reveal/Fade'

import f from '../../img/Accounts/img.png'

const data = [
    {
        title: 'accounts.3',
        text: 'accounts.8',
    },
    {
        title: 'accounts.4',   
        text: 'accounts.9',
    },
    {
        title: 'accounts.5',
        text: 'accounts.10',   
    },
    {
        title: 'accounts.6',
        text: 'accounts.11',   
    },
    {
        title: 'accounts.7',
        text: 'accounts.12',   
    },
]

function Main () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);
      
    return(
        <div className="relative overflow-hidden pt-[65px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                <p className="bebas text-[35px] xl:text-[70px] text-[#AD0921] leading-[1]">{t('accounts.1')}</p>
                <div className="flex flex-col xl:flex-row gap-[20px] xl:gap-[65px]">
                    <p className="bebas text-[25px] xl:text-[50px] text-[#171717] leading-[1]">{t('accounts.2')}</p>
                    {data.map((dat, index) =>(
                        <div key={index} className="flex items-center gap-[15px]">
                            <div className={`cursor-pointer transition-all w-[24px] h-[24px] ${active === index ? 'bg-[#AD0921] border border-[#AD0921]' : ' bg-white border border-[#000000]'}`} onClick={() => setActive(index)}></div>
                            <p className="inter font-[500] text-[#37393F] text-[17px]">{t(dat.title)}</p>
                        </div>
                    ))}
                </div>
                <div className="h-[250px] xl:h-[420px] mt-[30px]">
                    <img src={f} alt="" className=" object-cover h-full w-full"/>
                </div>
                <Fade key={active} bottom><p className="ml-auto mt-[-15px] xl:mt-[-35px] text-[40px] xl:text-[80px] text-[#AD0921] bebas leading-[1]">{t(data[active].title)}</p></Fade>
                <p className="max-w-[640px] inter text-[17px] font-[300] mt-[20px] xl:mt-0">{t(data[active].text)}</p>
            </div>
        </div>
    );
}

export default Main;