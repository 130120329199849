import React from "react";

import { useTranslation } from "react-i18next";

import signup from '../../img/Home/signup.png'
import signup_mob from '../../img/Home/signup_mob.png'

import Fade from 'react-reveal/Fade'

function SignUpToStart () {

    const { t, i18n } = useTranslation();

    const isBulgarian = i18n.language === 'bg';
      
    return(
        <div>
            <div className="pt-[80px] xl:pt-[200px]">
                <div className="mx-[20px] xl:mx-auto max-w-[1280px] bg-[#171717] xl:h-[426px] relative overflow-hidden xl:p-[70px]">
                    <p className={`text-[#AD0921] bebas ${isBulgarian ? 'text-[40px] xl:text-[70px]' : 'xl:text-[140px] text-[70px]'} leading-[1] px-[20px] xl:px-0 pt-[20px] xl:pt-0`}>{t('home.48')}</p>
                    <p className={`text-white bebas text-[35px] leading-[1] px-[20px] xl:px-0 ${isBulgarian ? '' : 'xl:text-[70px]'}`}>{t('home.49')}</p>
                    <div className="mt-[20px] xl:mt-[50px] pb-[20px] xl:pb-0">
                        <a href="https://user.anda-trade.net/" className="px-[20px] xl:px-0">
                            <div className="button_main !bg-white flex cursor-pointer gap-[20px]">
                                <p className="text-[#171717] inter font-[700] text-[16px]">{t('home.50')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                    <rect width="35" height="35" rx="17.5" fill="#AD0921"/>
                                    <path d="M7.29175 17.5H27.7084" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                    <Fade right>
                        <img src={signup} alt="" className="md:absolute right-[28px] top-0 mt-[20px] md:mt-0 hidden md:block"/>
                        <img src={signup_mob} alt="" className="md:absolute right-[28px] top-0 mt-[40px] xl:mt-0 md:hidden block w-full"/>
                    </Fade>                
                </div>
            </div>   
        </div>
    );
}

export default SignUpToStart;