import React, { useEffect } from "react";

import Main from '../components/Support/Main'
import Contacting from "../components/Support/Contacting";
import FAQ from "../components/Support/FAQ";
import SignUpToStart from "../components/Home/SignUpToStart";

function Support () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Contacting/>
            <FAQ/>
            <SignUpToStart/>
        </div>
    );
}

export default Support;