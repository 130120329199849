import React, { useState, useEffect } from "react";

import { useTranslation, Trans } from "react-i18next";

import axios from "axios";

import Marquee from "react-fast-marquee";

import Button from '../Button'

import m1 from '../../img/Home/1.png'
import m2 from '../../img/Home/2.png'
import round from '../../img/Home/round.png'

import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

const ForexAPI = "https://api.exchangerate-api.com/v4/latest/USD";

const TrendingCoins = (currency) =>
    `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&order=gecko_desc&per_page=10&page=1&sparkline=false&price_change_percentage=24h`;

function Main () {

    const { t, i18n } = useTranslation();

    const isBulgarian = i18n.language === 'bg';

    const [coins, setCoins] = useState([]);
    const [isError, setIsError] = useState(false);
    const currency = "usd";

    const [forexRates, setForexRates] = useState({});
    const [isForexError, setIsForexError] = useState(false);

    const fetchTradingCoins = async () => {
        try {
            const { data } = await axios.get(TrendingCoins(currency));
            setCoins(data);
            setIsError(false);
        } catch (error) {
            setIsError(true);
            console.error("Request error in fetchTradingCoins()", error);
        }
    };

    const fetchForexRates = async () => {
        try {
          const { data } = await axios.get(ForexAPI);
          setForexRates(data.rates); // Extracting rates from the response
          setIsForexError(false);
        } catch (error) {
          setIsForexError(true);
          console.error("Request error in fetchForexRates()", error);
        }
    };

    useEffect(() => {
        fetchTradingCoins();
        fetchForexRates(); // Fetch forex data when the component mounts
    }, [currency]);
      
    return(
        <div className="h-[1010px] overflow-hidden">
            <div className="relative overflow-hidden h-[800px] bg-[#171717]">
                <div className="flex flex-col md:flex-row justify-between mx-[20px] xl:mx-auto max-w-[1280px]">
                    <div className="mt-[50px] xl:mt-[180px]">
                        <p className={`bebas text-[#AD0921] leading-[1] ${isBulgarian ? 'text-[50px] xl:text-[100px]' : 'xl:text-[140px] text-[70px]'}`}>{t('home.1')}</p>
                        <p className="text-white bebas text-[35px] xl:text-[70px] leading-[1]">{t('home.2')}</p>
                        <p className="text-white bebas text-[45px] xl:text-[93px] leading-[1] mt-[20px]">{t('home.3')}</p>
                        <Button className="mt-[20px] xl:mt-[50px]"/>
                    </div>
                    <div className="mt-[60px] relative">
                        <Fade right><img src={m1} alt="" className="absolute top-[50px]"/></Fade>
                        <Rotate duration={2000}><img src={round} alt="" className="z-25 relative"/></Rotate>
                        <Fade right><img src={m2} alt="" className="absolute top-[50px]"/></Fade>
                    </div>
                </div>
            </div>
            <div className="relative">
                <div className="h-[60px] xl:h-[85px] wmainblock bg-[#AD0921] rotate-[5.189deg] absolute top-[10px] xl:top-[30px] -translate-x-[50px] flex items-center">
                    <Marquee autoFill direction="right">
                        <p className="text-white bebas text-[20px] xl:text-[28px] uppercase">{t('home.5')}</p>
                        <p className="text-white bebas text-[20px] xl:text-[28px] uppercase">{t('home.6')}</p>
                    </Marquee>
                </div>
                <div className="h-[60px] xl:h-[85px] wmainblock bg-[#4F4F4F] rotate-[-5.189deg] absolute top-[10px] xl:top-[30px] -translate-x-[50px] flex items-center">
                    <Marquee direction="left">
                        {coins.map((coin, index) => (
                            <div key={index} className="flex items-center text-[#FFF] text-[20px] xl:text-[28px] bebas font-[500] px-[10px]">
                                <img
                                    src={coin.image}
                                    alt={coin.symbol}
                                    className="w-[24px] h-[24px] xl:w-[40px] xl:h-[40px] mx-[10px]"
                                />
                                <span>
                                    {coin.symbol.toUpperCase()} ${coin.current_price.toFixed(2)} ({coin.price_change_percentage_24h.toFixed(2)}%) <span className="ml-[30px]">|</span>
                                </span>
                            </div>
                        ))}
                        {Object.keys(forexRates).map((currency, index) => (
                            <div key={index} className="text-white bebas text-[20px] xl:text-[28px] uppercase px-[20px]">
                                1 USD to {forexRates[currency].toFixed(2)} {currency} <span className="ml-[30px]">|</span>
                            </div>
                        ))}
                    </Marquee>
                </div>
            </div>      
        </div>
    );
}

export default Main;