import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import r1 from '../../img/Bonus/rec1.png'
import r2 from '../../img/Bonus/rec2.png'

function BonusSize () {

    const { t } = useTranslation();
      
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[200px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between">
                <div className="max-w-[525px]">
                    <p className="bebas text-[35px] xl:text-[70px] text-[#AD0921]">{t('bonus.16')}</p>
                    <p className="inter text-[17px] font-[700] mt-[30px]">{t('bonus.17')}</p>
                    <p className="max-w-[525px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030] mt-[15px]">{t('bonus.18')}</p>
                    <Fade left><img src={r1} alt="" className="mt-[20px] xl:mt-[65px]"/></Fade>
                    <p className="inter text-[17px] font-[700] mt-[20px] xl:mt-[65px]">{t('bonus.19')}</p>
                    <p className="max-w-[525px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030] mt-[15px]">{t('bonus.20')}</p>
                </div>
                <div className="max-w-[525px] mt-[20px] xl:mt-0">
                    <Fade right><img src={r2} alt="" /></Fade>
                    <p className="inter text-[17px] font-[700] mt-[20px] xl:mt-[65px]">{t('bonus.21')}</p>
                    <p className="max-w-[525px] inter text-[14px] xl:text-[17px] font-[200] text-[#303030] mt-[15px]">{t('bonus.22')}</p>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
            </div>
        </div>
    );
}

export default BonusSize;